import React from "react";
import { Element } from "react-scroll";
import { FaBuilding, FaUniversity, FaFlask, FaBriefcase, FaToolbox, FaIndustry, FaBookOpen, FaHospitalAlt, FaMortarPestle, FaCapsules} from "react-icons/fa";
import "./Cases.css";
import { useTranslation } from "react-i18next";

function Cases() {
  const { t } = useTranslation();

  // Lista de clientes con íconos
  const clients = [
    { name: "AE Tech", icon: <FaBuilding /> },
    { name: "Interwin", icon: <FaBuilding /> },
    { name: "MaximRentals", icon: <FaBuilding /> },
    { name: "APTO Bio", icon: <FaHospitalAlt /> },
    { name: "Farmacity", icon: <FaCapsules /> },    

    { name: "Laboratorio Pablo Cassará", icon: <FaFlask /> },
    { name: "Laboratorio Austral", icon: <FaFlask /> },
    { name: "Laboratorio Lepetit", icon: <FaFlask /> },
    { name: "Instituto Sanitas Argentina", icon: <FaFlask /> },
    { name: "Kilab", icon: <FaFlask /> },
    
    { name: "Universidad de Buenos Aires (UBA)", icon: <FaUniversity /> },    
    { name: "Escuela Nacional de Buceo (ENBAS)", icon: <FaBookOpen /> },
    { name: "Oholey", icon: <FaUniversity /> },
    
    { name: "Rotadyne", icon: <FaIndustry /> },
    { name: "Parker", icon: <FaIndustry /> },
    { name: "Telas por Metro", icon: <FaIndustry /> },
    
  ];

  return (
    <Element name="cases" className="section">
      <h2 className="section-title">{t("cases.title")}</h2>
      <div className="clients-grid">
        {clients.map((client, index) => (
          <div className="client-card" key={index}>
            <div className="client-icon">{client.icon}</div>
            <div className="client-name">{client.name}</div>
          </div>
        ))}
      </div>
    </Element>
  );
}

export default Cases;
