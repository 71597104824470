import React from 'react';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import "./Team.css";
function Team() {
  const { t } = useTranslation();

  const teamMembers = [
    { name: 'Jonathan Jais', role: t('team.roles.cto'), image: './../../images/jonathan.jpg' },
    { name: 'Andres Calderone', role: t('team.roles.developer'), image: './../../images/andres.jpg' },
    { name: 'Hugo Sajama', role: t('team.roles.developer') }, // Sin imagen
    { name: 'Mailen Sajama', role: t('team.roles.designer'), image: './../../images/mailen.jpg' },
  ];

  return (
    <Element name="team" className="section">
      <section>
        <h2 className="section-title">{t('team.title')}</h2>
        <div className="team-container">
          {teamMembers.map((member, index) => (
            <article key={index} className="team-card">
              <Avatar
                alt={member.name}
                src={member.image} // Usa la imagen si está disponible
                sx={{
                  width: 120, // Tamaño del avatar
                  height: 120,
                  fontSize: '2rem', // Tamaño del texto si usa las iniciales
                }}
              >
                {!member.image && member.name.charAt(0)} {/* Inicial si no hay imagen */}
              </Avatar>
              <h3 className="team-name">{member.name}</h3>
              <p className="team-role">{member.role}</p>
            </article>
          ))}
        </div>
      </section>
    </Element>
  );
}

export default Team;
