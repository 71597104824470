import React from "react";

function DatabasesAndVisualization() {
  const tools = [
    { name: "Big Query", icon: "🔍" },
    { name: "MongoDB", icon: "🍃" },
    { name: "SQL Server | MSSQL", icon: "💾" },
    { name: "MySQL", icon: "🐬" },
    { name: "PostgreSQL | PGSQL", icon: "🦉" },
    { name: "Google Looker Studio", icon: "📊" },
    { name: "Power BI", icon: "📈" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Bases de Datos y Herramientas de Visualización</h3>
      <div className="tech-grid">
        {tools.map((tool, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{tool.icon}</div>
            <div className="tech-name">{tool.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DatabasesAndVisualization;
