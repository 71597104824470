import React from "react";

function CloudPlatforms() {
  const platforms = [
    { name: "Auth0", icon: "🔑" },
    { name: "DonWeb", icon: "🌐" },
    { name: "Firebase", icon: "🔥" },
    { name: "Fly IO", icon: "✈️" },
    { name: "GoDaddy", icon: "🌎" },
    { name: "Google Cloud Platform", icon: "☁️" },
    { name: "Google Maps API", icon: "🗺️" },
    { name: "Google Workspace", icon: "📂" },
    { name: "Hostinger", icon: "🏠" },
    { name: "Office 365", icon: "📄" },
    { name: "Vercel", icon: "🚀" },
    { name: "Azure", icon: "🔵" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Plataformas y Servicios en la Nube</h3>
      <div className="tech-grid">
        {platforms.map((platform, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{platform.icon}</div>
            <div className="tech-name">{platform.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CloudPlatforms;
