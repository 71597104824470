import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import "./Navbar.css";
import "flag-icons/css/flag-icons.min.css";
import logo from "./../images/Logo-LikeNData-BLANCO.png";

function Navbar() {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const menuItems = [
    { name: "home", label: t("navbar.home") },
    { name: "about", label: t("navbar.about") },
    { name: "services", label: t("navbar.services") },
    { name: "cases", label: t("navbar.cases") },
    { name: "technologies", label: t("navbar.technologies") },
    { name: "team", label: t("navbar.team") },
    { name: "contact", label: t("navbar.contact") },
  ];

  useEffect(() => {
    const sections = document.querySelectorAll(".section");
    const observerOptions = {
      root: null, // Usa el viewport completo
      threshold: 0.5, // Consideramos activa una sección si al menos el 50% es visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.getAttribute("name"));
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <nav className="navbar" aria-label="Main Navigation">
      <div className="navbar-brand">
        <button className="navbar-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <img src={logo} alt="LikenData Logo" className="navbar-logo" />
        
      </div>
      <ul className={`navbar-menu ${menuOpen ? "active" : ""}`}>
        {menuItems.map((item) => (
          <li key={item.name}>
            <Link
              to={item.name}
              spy={false} // Desactivamos spy porque usamos IntersectionObserver
              smooth={true}
              offset={-70}
              duration={500}
              className={activeSection === item.name ? "active" : ""}
              onClick={() => setMenuOpen(false)}
            >
              {item.label}
            </Link>
          </li>
        ))}
        <li className="language-selector">
          <span
            className={`fi fi-ar ${
              i18n.language === "es" ? "active-flag" : ""
            }`}
            onClick={() => changeLanguage("es")}
            title="Español"
          ></span>
          <span
            className={`fi fi-us ${
              i18n.language === "en" ? "active-flag" : ""
            }`}
            onClick={() => changeLanguage("en")}
            title="English"
          ></span>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
