import React from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./About.css";
import aboutImage from "./../../images/about.webp";

function About() {
  const { t } = useTranslation();

  return (
    <Element name="about" className="section about-section">
      <section className="about-container">
        <div className="about-content">
          <h2 className="section-title">{t("about.title")}</h2>
          <p className="about-description">{t("about.description")}</p>
          <ul className="about-list">
            <li>
              <span className="list-icon">✓</span>
              <span className="list-text">{t("about.innovation")}</span>
            </li>
            <li>
              <span className="list-icon">✓</span>
              <span className="list-text">{t("about.quality")}</span>
            </li>
            <li>
              <span className="list-icon">✓</span>
              <span className="list-text">{t("about.commitment")}</span>
            </li>
          </ul>
        </div>
        <div className="about-image-container">
          <img src={aboutImage} alt="About LikenData" className="about-image" />
        </div>
      </section>
    </Element>
  );
}

export default About;
