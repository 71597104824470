import React from "react";

function OtherServices() {
  const otherServices = [
    { name: "Gestión de Dominios", icon: "🌐" },
    { name: "Gestión de Licencias", icon: "🔑" },
    { name: "Bejerman", icon: "📊" },
    { name: "Binsol", icon: "⚙️" },
    { name: "GACI", icon: "📂" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Otros Servicios</h3>
      <div className="tech-grid">
        {otherServices.map((service, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{service.icon}</div>
            <div className="tech-name">{service.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OtherServices;
