import React from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import ProgrammingFrameworks from "./ProgrammingFrameworks";
import ServerTechnologies from "./ServerTechnologies";
import CloudPlatforms from "./CloudPlatforms";
import CollaborationTools from "./CollaborationTools";
import DatabasesAndVisualization from "./DatabasesAndVisualization";
import DevelopmentTools from "./DevelopmentTools";
import OtherServices from "./OtherServices";
import AWS from "./AWS";
import "./Technologies.css";

function Technologies() {
  const { t } = useTranslation(); // Inicializa la función de traducción

  return (
    <Element name="technologies" className="section">
      <h2 className="section-title">{t("technologies.title")}</h2>
      <ProgrammingFrameworks />
      <ServerTechnologies />
      <CloudPlatforms />
      <CollaborationTools />
      <DatabasesAndVisualization />
      <DevelopmentTools />
      <OtherServices />
      <AWS />
    </Element>
  );
}

export default Technologies;
