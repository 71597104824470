import React from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./Services.css";

function Services() {
  const { t } = useTranslation();

  const mainServices = [
    {
      title: t("services.softwareDevelopment"),
      description: t("services.softwareDevelopmentDesc"),
      icon: "🖥️",
    },
    {
      title: t("services.artificialIntelligence"),
      description: t("services.artificialIntelligenceDesc"),
      icon: "🤖",
    },
    {
      title: t("services.dataVisualization"),
      description: t("services.dataVisualizationDesc"),
      icon: "📊",
    },
  ];

  const complementaryServices = [
    {
      title: t("services.apiIntegration"),
      description: t("services.apiIntegrationDesc"),
      icon: "🔗",
    },
    {
      title: t("services.processOptimization"),
      description: t("services.processOptimizationDesc"),
      icon: "⚙️",
    },
    {
      title: t("services.uxDesign"),
      description: t("services.uxDesignDesc"),
      icon: "🎨",
    },
    {
      title: t("services.projectManagement"),
      description: t("services.projectManagementDesc"),
      icon: "📂",
    },
    {
      title: t("services.cloudSolutions"),
      description: t("services.cloudSolutionsDesc"),
      icon: "☁️",
    },
    {
      title: t("services.noCodeDevelopment"),
      description: t("services.noCodeDevelopmentDesc"),
      icon: "📱",
    },
    {
      title: t("services.infrastructureManagement"),
      description: t("services.infrastructureManagementDesc"),
      icon: "🏢",
    },
    {
      title: t("services.techSupport"),
      description: t("services.techSupportDesc"),
      icon: "🛠️",
    },
  ];

  return (
    <Element name="services" className="section services-section">
      <section>
        <h2 className="section-title">{t("services.title")}</h2>
        <div className="services-grid">
          <h3 className="services-subtitle">{t("services.mainTitle")}</h3>
          <div className="services-main">
            {mainServices.map((service, index) => (
              <div key={index} className="service-card">
                <span className="service-icon">{service.icon}</span>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
          <h3 className="services-subtitle">{t("services.complementaryTitle")}</h3>
          <div className="services-complementary">
            {complementaryServices.map((service, index) => (
              <div key={index} className="service-card">
                <span className="service-icon">{service.icon}</span>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Element>
  );
}

export default Services;
