import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Traducciones
const resources = {
  en: {
    translation: {
      navbar: {
        home: "Home",
        about: "About",
        services: "Services",
        cases: "Cases",
        technologies: "Technologies",
        team: "Team",
        contact: "Contact",
        language: "Language",
      },
      hero: {
        subtitle:
          "Innovative technological solutions to enhance your business.",
        contactButton: "Contact Us",
      },
      about: {
        title: "About Us",
        description:
          "At LikenData, we are your strategic partner in digital transformation, helping businesses achieve their goals with tailored technological solutions.",
        innovation:
          "Innovation: Cutting-edge technologies for creative solutions.",
        quality: "Quality: High-standard services to exceed expectations.",
        commitment:
          "Commitment: Close collaboration with our clients for mutual success.",
      },
      services: {
        title: "Our Services",
        mainTitle: "Main Services",
        complementaryTitle: "Complementary Services",
        softwareDevelopment: "Enterprise Software Development",
        softwareDevelopmentDesc:
          "We create custom platforms to optimize operations and deliver tangible results.",
        artificialIntelligence: "Artificial Intelligence and Machine Learning",
        artificialIntelligenceDesc:
          "Predictive and automated solutions to transform data into strategic decisions.",
        dataVisualization: "Enterprise Data Visualization",
        dataVisualizationDesc:
          "Interactive dashboards to facilitate data-driven decision-making.",
        apiIntegration: "Enterprise API Integration",
        apiIntegrationDesc:
          "We connect internal systems with external applications for seamless workflows.",
        processOptimization: "Enterprise Process Optimization",
        processOptimizationDesc:
          "We redesign workflows to maximize organizational efficiency.",
        uxDesign: "Enterprise UX/UI Design",
        uxDesignDesc: "Intuitive interfaces designed to boost productivity.",
        projectManagement: "Agile Project Management",
        projectManagementDesc:
          "We implement agile methodologies for high-impact projects.",
        cloudSolutions: "Cloud Solutions",
        cloudSolutionsDesc:
          "Cloud platform migration and management, ensuring scalability and security.",
        noCodeDevelopment: "NoCode Development with Appsheet",
        noCodeDevelopmentDesc:
          "Quick creation of solutions tailored to your business needs.",
        infrastructureManagement: "OnPremise Infrastructure Management",
        infrastructureManagementDesc:
          "Reliable and customized management of local servers and data centers.",
        techSupport: "Enterprise Tech Support",
        techSupportDesc:
          "Comprehensive assistance to keep critical systems running smoothly.",
      },

      cases: {
        title: "Success Stories",
        realEstate: "Real Estate",
        health: "Health",
        education: "Education",
        others: "Others",
      },

      technologies: {
        title: "Technologies and Tools",
      },

      team: {
        title: "Our Team",
        description:
          "Meet our team of highly skilled professionals ready to solve your challenges.",
        roles: {
          cto: "Chief Technology Officer",
          developer: "Developer",
          designer: "Designer",
        },
      },

      contact: {
        title: "Contact Us",
        email: "Email",
        phone: "Phone",
        address: "Address",
        emailButton: "Send Email",
        callButton: "Call Us",
      },

      footer: {
        rightsReserved: "All rights reserved.",
      },
    },
  },
  es: {
    translation: {
      navbar: {
        home: "Inicio",
        about: "Nosotros",
        services: "Servicios",
        cases: "Casos de Éxito",
        technologies: "Tecnologías",
        team: "Equipo",
        contact: "Contacto",
        language: "Idioma",
      },
      hero: {
        subtitle:
          "Soluciones tecnológicas innovadoras para potenciar tu negocio.",
        contactButton: "Contáctanos",
      },
      about: {
        title: "Sobre Nosotros",
        description:
          "En LikenData, somos tu socio estratégico en la transformación digital, ayudando a las empresas a alcanzar sus metas con soluciones tecnológicas personalizadas.",
        innovation:
          "Innovación: Tecnologías de vanguardia para soluciones creativas.",
        quality:
          "Calidad: Servicios de alta excelencia para superar expectativas.",
        commitment:
          "Compromiso: Colaboración cercana con nuestros clientes para el éxito conjunto.",
      },

      services: {
        title: "Nuestros Servicios",
        mainTitle: "Servicios Principales",
        complementaryTitle: "Servicios Complementarios",
        softwareDevelopment: "Desarrollo de Software Empresarial",
        softwareDevelopmentDesc:
          "Creamos plataformas personalizadas para optimizar operaciones y generar resultados tangibles.",
        artificialIntelligence: "Inteligencia Artificial y Machine Learning",
        artificialIntelligenceDesc:
          "Soluciones predictivas y automatizadas para transformar datos en decisiones estratégicas.",
        dataVisualization: "Visualización de Datos Empresariales",
        dataVisualizationDesc:
          "Dashboards interactivos que facilitan la toma de decisiones basadas en datos.",
        apiIntegration: "Integración de APIs Empresariales",
        apiIntegrationDesc:
          "Conectamos sistemas internos con aplicaciones externas para procesos fluidos.",
        processOptimization: "Optimización de Procesos Empresariales",
        processOptimizationDesc:
          "Rediseñamos flujos de trabajo para maximizar la eficiencia organizacional.",
        uxDesign: "Diseño de UX/UI Empresarial",
        uxDesignDesc:
          "Interfaces intuitivas diseñadas para aumentar la productividad.",
        projectManagement: "Gestión de Proyectos Ágiles",
        projectManagementDesc:
          "Implementamos metodologías ágiles para proyectos de alto impacto.",
        cloudSolutions: "Soluciones en la Nube",
        cloudSolutionsDesc:
          "Migración y gestión en plataformas cloud, garantizando escalabilidad y seguridad.",
        noCodeDevelopment: "Desarrollo NoCode con Appsheet",
        noCodeDevelopmentDesc:
          "Creación rápida de soluciones adaptadas a tus necesidades empresariales.",
        infrastructureManagement: "Administración de Infraestructura OnPremise",
        infrastructureManagementDesc:
          "Gestión confiable y personalizada de servidores locales y data centers.",
        techSupport: "Soporte Tecnológico Empresarial",
        techSupportDesc:
          "Asistencia integral para mantener sistemas críticos en funcionamiento.",
      },

      cases: {
        title: "Casos de Éxito",
        realEstate: "Real Estate",
        health: "Salud",
        education: "Educación",
        others: "Otros",
      },

      technologies: {
        title: "Tecnologías y Herramientas",
      },

      team: {
        title: "Nuestro Equipo",
        description:
          "Conoce a nuestro equipo de profesionales altamente calificados listos para resolver tus desafíos.",
        roles: {
          cto: "Director de Tecnología",
          developer: "Desarrollador",
          designer: "Diseñadora",
        },
      },

      contact: {
        title: "Contáctanos",
        email: "Correo Electrónico",
        phone: "Teléfono",
        address: "Dirección",
        emailButton: "Enviar Correo",
        callButton: "Llamar",
      },

      footer: {
        rightsReserved: "Todos los derechos reservados.",
      },
    },
  },
};

// Configuración de i18n
i18n
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(initReactI18next) // Integración con React
  .init({
    resources,
    fallbackLng: "es", // Idioma por defecto
    interpolation: {
      escapeValue: false, // React ya maneja el escape de valores
    },
  });

export default i18n;
