import React from "react";
import "./HeroSection.css";
import logo from "./../../images/Logo-LikeNData-BLANCO.png";
import { useTranslation } from "react-i18next";

function HeroSection() {
  const { t } = useTranslation();

  return (
    <div id="home" className="hero-section">
      <div className="hero-content">
        <img src={logo} alt="LikenData Logo" className="hero-logo" />
        <p className="hero-subtitle">{t("hero.subtitle")}</p>
        <button className="hero-button">{t("hero.contactButton")}</button>
      </div>
    </div>
  );
}

export default HeroSection;
