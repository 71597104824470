import React from "react";

function ServerTechnologies() {
  const serverTechnologies = [
    { name: "Apache", icon: "🌐" },
    { name: "Asterisk", icon: "📞" },
    { name: "Debian", icon: "🐧" },
    { name: "Docker", icon: "🐳" },
    { name: "Fortigate", icon: "🛡️" },
    { name: "IIS", icon: "📂" },
    { name: "Jetty", icon: "🚀" },
    { name: "Kafka", icon: "🧵" },
    { name: "Kubernetes", icon: "⛵" },
    { name: "Moodle", icon: "📘" },
    { name: "NGINX", icon: "🔧" },
    { name: "RDP", icon: "🖥️" },
    { name: "Strapi CMS", icon: "📋" },
    { name: "Terraform", icon: "🗺️" },
    { name: "Tomcat", icon: "🐱" },
    { name: "Ubiquiti", icon: "📡" },
    { name: "Ubuntu", icon: "🟠" },
    { name: "Veeam Backup", icon: "💾" },
    { name: "Veeam One", icon: "🔄" },
    { name: "VMware | ESXi", icon: "🖧" },
    { name: "Webmin", icon: "⚙️" },
    { name: "Windows Server", icon: "🪟" },
    { name: "Windows Server | AD", icon: "📜" },
    { name: "Windows Server | DNS", icon: "🌍" },
    { name: "Windows Server | RDP", icon: "🖥️" },
    { name: "Windows Server | WSUS", icon: "🔄" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Tecnologías de Servidores y Redes</h3>
      <div className="tech-grid">
        {serverTechnologies.map((tech, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{tech.icon}</div>
            <div className="tech-name">{tech.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServerTechnologies;
