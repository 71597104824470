import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="simple-footer">
      <p>
        © {new Date().getFullYear()} LikenData. {t('footer.rightsReserved')}
      </p>
    </footer>
  );
}

export default Footer;
