import React from "react";

function CollaborationTools() {
  const tools = [
    { name: "Confluence", icon: "📘" },
    { name: "Figma", icon: "🎨" },
    { name: "Jira", icon: "📊" },
    { name: "Mantis", icon: "🐜" },
    { name: "Slack", icon: "💬" },
    { name: "Trello", icon: "📋" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Herramientas de Colaboración</h3>
      <div className="tech-grid">
        {tools.map((tool, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{tool.icon}</div>
            <div className="tech-name">{tool.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollaborationTools;
