import React from "react";

function AWS() {
  const awsServices = [
    { name: "ALB", icon: "☁️" },
    { name: "API Gateway", icon: "🚪" },
    { name: "Amplify", icon: "🔊" },
    { name: "Aurora", icon: "💡" },
    { name: "CLI", icon: "💻" },
    { name: "Certificate Manager", icon: "📜" },
    { name: "CloudFormation", icon: "🏗️" },
    { name: "CloudFront", icon: "🌍" },
    { name: "CloudSearch", icon: "🔎" },
    { name: "CloudWatch", icon: "⏱️" },
    { name: "CodeCommit", icon: "📝" },
    { name: "CodePipeline", icon: "🚀" },
    { name: "Cognito", icon: "🧑‍💻" },
    { name: "Control Tower", icon: "🏢" },
    { name: "Cost Explorer", icon: "💰" },
    { name: "DB Migration Service", icon: "📦" },
    { name: "DynamoDB", icon: "📊" },
    { name: "EBS", icon: "🖴" },
    { name: "EC2", icon: "🖥️" },
    { name: "ECR", icon: "🐳" },
    { name: "ECS", icon: "🛠️" },
    { name: "EFS", icon: "📁" },
    { name: "EKS", icon: "⛵" },
    { name: "ELB", icon: "🔗" },
    { name: "ElastiCache", icon: "🗂️" },
    { name: "Elastic Container Service", icon: "📦" },
    { name: "Elasticsearch Service", icon: "🔍" },
    { name: "Fargate", icon: "🛳️" },
    { name: "Global Accelerator", icon: "🌎" },
    { name: "IAM", icon: "🔐" },
    { name: "Kinesis", icon: "📈" },
    { name: "Lambda", icon: "λ" },
    { name: "MQ", icon: "📬" },
    { name: "RDS", icon: "💾" },
    { name: "Route 53", icon: "🗺️" },
    { name: "S3", icon: "📤" },
    { name: "SNS", icon: "🔔" },
    { name: "SQS", icon: "📬" },
    { name: "Storage Gateway", icon: "📦" },
    { name: "TensorFlow", icon: "🧠" },
    { name: "VPC", icon: "🔗" },
    { name: "WAF & Shield", icon: "🛡️" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Servicios de AWS</h3>
      <div className="tech-grid">
        {awsServices.map((service, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{service.icon}</div>
            <div className="tech-name">{service.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AWS;
