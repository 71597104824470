import React from "react";

function ProgrammingFrameworks() {
  const programmingFrameworks = [
    { name: "Apache POI", icon: "📘" },
    { name: "C | C++", icon: "💻" },
    { name: "Django", icon: "🌐" },
    { name: "Gradle", icon: "⚙️" },
    { name: "Haskenestjsll", icon: "📐" },
    { name: "Hibernate", icon: "🗂️" },
    { name: "Java", icon: "☕" },
    { name: "JavaScript", icon: "✨" },
    { name: "JQuery", icon: "📜" },
    { name: "Laravel", icon: "🎨" },
    { name: "Maven", icon: "🔧" },
    { name: "NestJs", icon: "🏗️" },
    { name: "NextJs", icon: "🔲" },
    { name: "NodeJs", icon: "🌱" },
    { name: "NuxtJS", icon: "🔳" },
    { name: "PHP", icon: "🐘" },
    { name: "Prisma IO", icon: "📊" },
    { name: "Prolog", icon: "📚" },
    { name: "Python", icon: "🐍" },
    { name: "ReactJS", icon: "⚛️" },
    { name: "Ruby", icon: "💎" },
    { name: "Scala", icon: "🔺" },
    { name: "Solidity", icon: "⛓️" },
    { name: "Spring", icon: "🌱" },
    { name: "Spring Boot", icon: "🚀" },
    { name: "SQL | T-SQL", icon: "🛢️" },
    { name: "TypeScript", icon: "📘" },
    { name: "Vert.x", icon: "🌀" },
    { name: "Vue", icon: "🖼️" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Lenguajes de Programación y Frameworks</h3>
      <div className="tech-grid">
        {programmingFrameworks.map((framework, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{framework.icon}</div>
            <div className="tech-name">{framework.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProgrammingFrameworks;
