import React, { useEffect } from 'react';
import Navbar from "./components/Navbar";
import HeroSection from "./components/sections/HeroSection";
import About from "./components/sections/About";
import Services from "./components/sections/Services";
import Cases from "./components/sections/Cases";
import Technologies from "./components/sections/Technologies/Technologies";
import Team from "./components/sections/Team";
import Contact from "./components/sections/Contact";
import "./App.css";
import Footer from "./components/Footer";
import { Analytics } from '@vercel/analytics/react';

function App() {
  
  useEffect(() => {
    // Configurar Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-G3H1VQV941");
  }, []);


  return (
    <div className="main-container">
      <Navbar />
      <main>
        <HeroSection />
        <About />
        <Services />
        <Cases />
        <Technologies />
        <Team />
        <Contact />
        <Footer />
        <Analytics />
      </main>
    </div>
    
  );
}

export default App;
