import React from "react";

function DevelopmentTools() {
  const tools = [
    { name: "CSS", icon: "🎨" },
    { name: "Git", icon: "🌱" },
    { name: "GitHub", icon: "🐙" },
    { name: "GitLab", icon: "🦊" },
    { name: "gRPC", icon: "📡" },
    { name: "HTML", icon: "🌐" },
    { name: "JSON", icon: "📜" },
    { name: "JWT", icon: "🔐" },
    { name: "Microservices", icon: "🔧" },
    { name: "Postman", icon: "📮" },
    { name: "REST", icon: "🔗" },
    { name: "SOAP", icon: "🧼" },
    { name: "SoapUI", icon: "🛁" },
    { name: "XML", icon: "📄" },
    { name: "YAML", icon: "📋" },
  ];

  return (
    <div className="tech-category">
      <h3 className="category-title">Herramientas y Protocolos de Desarrollo</h3>
      <div className="tech-grid">
        {tools.map((tool, index) => (
          <div className="tech-card" key={index}>
            <div className="tech-icon">{tool.icon}</div>
            <div className="tech-name">{tool.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DevelopmentTools;
