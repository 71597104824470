import React from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import "./Contact.css";

function Contact() {
  const { t } = useTranslation();

  return (
    <Element name="contact" className="section contact-section">
      <div className="contact-container">
        <h2 className="section-title">{t("contact.title")}</h2>
        <div className="contact-info">
          <div className="contact-item">
            <FaEnvelope className="contact-icon" />
            <a href="mailto:info@likendata.com" className="contact-link">
              info@likendata.com
            </a>
          </div>
          <div className="contact-item">
            <FaPhone className="contact-icon" />
            <span className="contact-text">+54 9 11 6650 1097</span>
          </div>
          <div className="contact-item">
            <FaMapMarkerAlt className="contact-icon" />
            <span className="contact-text">Buenos Aires, Argentina</span>
          </div>
        </div>
        <div className="contact-buttons">
          <a href="mailto:info@likendata.com" className="button">
            {t("contact.emailButton")}
          </a>
          <a href="tel:+5491166501097" className="button">
            {t("contact.callButton")}
          </a>
        </div>
      </div>
    </Element>
  );
}

export default Contact;
